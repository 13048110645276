import { Account } from '../interfaces/account';
import { apiClient } from '../libs/axios';

const getAccount = async () => {
  const { data } = await apiClient.get<Account>('/accounts/me', {
    params: {
      include: 'accountSetting,planModules.features',
      'fields[accountSetting]': 'onlineMenu',
    },
  });

  return data;
};

export { getAccount };
